import TYPES from '@/types';

import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class LinkedCustomInvestorGoalMenuCardViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.linked-custom-investor-goal-card';

  readonly view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showContributeDialog = () => {
    this.view.$emit('showContributeDialog');
  }

  showChooseActionEditGoalDialog = () => {
    this.view.$emit('showChooseActionEditGoalDialog');
  }

  showDeleteCustomInvestorGoalModal = () => {
    this.view.$emit('showDeleteCustomInvestorGoalModal');
  }

  showModifyStrategyFlow = () => {
    this.view.$emit('showModifyStrategyFlow');
  }
}
